<template>
	<div>
		<b-card no-body>
			<b-tabs card>
				<b-tab title="Primary Information" active>
					<b-card-text>
						<b-form-group label="Name" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ row.item.name }}
						</b-form-group>

						<b-form-group label="Description" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							<span class="truncate-text">
								<truncate collapsed-text-class="collapsed" clamp="Show More" :length="100" less="Show Less"
									type="html" :text="row.item.description" />
							</span>
						</b-form-group>

						<b-form-group label="Company" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ row.item.company }}
						</b-form-group>

						<b-form-group label="Address" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ row.item.address }}
						</b-form-group>

						<b-form-group label="Area Radius (m)" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							<span class="numFont">
								{{ row.item.areaRadius }}
							</span>
						</b-form-group>

						<b-form-group label="Geoaddress" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Map Location'"
								@click.stop="showStorageLocation(row.item)" variant="dark" class="mr-1">
								<i class="fa fa-map-marker fa-lg"></i>
							</b-button>
							<span class="numFont">
								{{ getCoordinates(row.item.geoaddress) }}
							</span>
						</b-form-group>

						<b-form-group label="Date Created" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ getFormattedDateWithTime(row.item.dateCreated) }}
						</b-form-group>

						<b-form-group label="Date Updated" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ getFormattedDateWithTime(row.item.dateUpdated) }}
						</b-form-group>

						<b-form-group label="Status" label-cols-sm="3" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							<span v-if="row.item.isActive === 'true'">
								<b-badge variant="success" class="badgeFont">Active</b-badge>
							</span>
							<span v-else>
								<b-badge variant="secondary" class="badge">Inactive</b-badge>
							</span>
						</b-form-group>
					</b-card-text>
				</b-tab>
				<b-tab title="Change Logs">
					<StorageLocationChangeLogsDetailsView :row="row" />
				</b-tab>
			</b-tabs>
		</b-card>
		<StorageLocationView :storageLocation="row.item" />
	</div>
</template>

<script>
// Component
import StorageLocationView from '@/views/setup/storageLocation/StorageLocationView';
import StorageLocationChangeLogsDetailsView from './StorageLocationChangeLogsDetailsView.vue';

// Util
import { LocationUtil } from '@/utils/locationUtil';
import { DateUtil } from '@/utils/dateutil';

// Others
import truncate from 'vue-truncate-collapsed';

export default {
	name: 'storage-location-details-view',
	components: {
		StorageLocationView,
		StorageLocationChangeLogsDetailsView,
		truncate
	},
	props: {
		row: {
			type: Object,
			required: true,
		},
	},
	methods: {
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		getCoordinates(geoaddress) {
			geoaddress = LocationUtil.getGeoaddress(geoaddress);

			return (
				'(' +
				geoaddress.latitude.toFixed(6) +
				', ' +
				geoaddress.longitude.toFixed(6) +
				')'
			);
		},
		showStorageLocation(item) {
			this.$bvModal.show('storage-location-view-' + item.id);
		},
	},
};
</script>