<template>
    <div>
        <!-- Select Actions and Items Per Page Options -->
        <b-row>
            <b-col sm="6" md="6">
                <span class="details-view-title">CHANGE LOGS</span>
                <div class="mb-2 details-view-subtitle">Recent update logs created by {{ isSuperAdmin ? 'all users' :
                    loggedUser.id }}</div>
            </b-col>
            <b-col sm="6" md="4" offset-md="2" class="mb-2 text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="changeLogs" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" responsive>
            <template v-slot:cell(dateUpdated)="row">
                <div>
                    {{ getFormattedDateWithTime(row.item.new.dateUpdated) }}
                </div>
            </template>
            <template v-slot:cell(changes)="row">
                <div class="changed-section" v-if="hasValueChanged(row.item, 'description')">
                    <b>Description</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'description') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'description') }} </b>
                </div>
                <div class="changed-section" v-if="hasValueChanged(row.item, 'address')">
                    <b>Address</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'address') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'address') }} </b>
                </div>
                <div class="changed-section" v-if="hasChangesInNestedFields(row.item, 'geoaddress', 'latitude')">
                    <b>Latitude</b>
                    <br>
                    From <b class="old-value numFont"> {{ row.item.old ? row.item.old.geoaddress.latitude : '0' }} </b>
                    to <b class="new-value numFont"> {{ row.item.new ? row.item.new.geoaddress.latitude : '0' }} </b>
                </div>
                <div class="changed-section" v-if="hasChangesInNestedFields(row.item, 'geoaddress', 'longitude')">
                    <b>Longitude</b>
                    <br>
                    From <b class="old-value numFont"> {{ row.item.old ? row.item.old.geoaddress.longitude : '0' }} </b>
                    to <b class="new-value numFont"> {{ row.item.new ? row.item.new.geoaddress.longitude : '0' }} </b>
                </div>
                <div class="changed-section" v-if="hasValueChanged(row.item, 'areaRadius')">
                    <b>Area Radius</b>
                    <br>
                    From <b class="old-value numFont"> {{ row.item.old ? row.item.old.areaRadius : '0' }} </b>
                    to <b class="new-value numFont"> {{ row.item.new ? row.item.new.areaRadius : '0' }} </b>
                </div>
                <div class="changed-section" v-if="hasValueChanged(row.item, 'isActive')">
                    <b>Status</b>
                    <br>
                    From <span v-if="row.item.old !== null">
                        <StorageLocationStatus :storageLocation="row.item.old" />
                    </span>
                    <span v-else>&nbsp;-&nbsp;</span>
                    to
                    <StorageLocationStatus :storageLocation="row.item.new" />
                </div>
            </template>
            <template v-slot:cell(updatedBy)="row">{{ row.item.new.updatedBy }}</template>
        </b-table>
    </div>
</template>

<script>
// Utils
import { DateUtil } from '@/utils/dateutil';

// Components
import StorageLocationStatus from '@/views/setup/storageLocation/StorageLocationStatus';

// DAO
import auditTrailLogsDAO from '@/database/auditTrailLogs';

// Others
import _ from 'lodash';

export default {
    name: 'storage-location-change-logs-details-view',
    components: {
        StorageLocationStatus
    },
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            changeLogs: [],
            fields: [
                'dateUpdated',
                'changes',
                'updatedBy',
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            storageLocationId: '',

            loggedUser: this.$store.getters.loggedUser,
            isSuperAdmin: this.$store.getters.isSuperAdmin,
        }
    },
    watch: {
        storageLocationId: async function (newVal) {
            if (newVal && newVal.length > 0) {
                await this.retrieveChangeLog(newVal);
            }
        }
    },
    async mounted() {
        this.storageLocationId = this.row.item.id;
        await this.retrieveChangeLog(this.storageLocationId);
    },
    methods: {
        async retrieveChangeLog(storageLocationId) {
            if (storageLocationId) {
                let param = {
                    collection: 'storageLocations',
                    id: storageLocationId,
                    userId: !this.isSuperAdmin ? this.loggedUser.id : ''
                }

                let results = await auditTrailLogsDAO.getAuditTrailLogs(param);
                this.changeLogs = Object.values(results);

                this.filterChangeLogs(this.changeLogs);
                this.totalRows = _.size(this.changeLogs);
            }
        },
        filterChangeLogs(changeLogs) {
            const filteredLogs = _.filter(changeLogs, log => {
                const oldLog = log.old ? log.old : {};
                const newLog = log.new ? log.new : {};

                // Description
                const oldDesc = oldLog.description ? oldLog.description : '';
                const newDesc = newLog.description ? newLog.description : '';
                // Address
                const oldAddress = oldLog.address ? oldLog.address : '';
                const newAddress = newLog.address ? newLog.address : '';
                // Latitude
                const oldLatitude = oldLog.geoaddress ? oldLog.geoaddress.latitude : 0;
                const newLatitude = newLog.geoaddress ? newLog.geoaddress.latitude : 0;
                // Longitude
                const oldLongitude = oldLog.geoaddress ? oldLog.geoaddress.longitude : 0;
                const newLongitude = newLog.geoaddress ? newLog.geoaddress.longitude : 0;
                // Area Radius
                const oldAreaRadius = oldLog.areaRadius ? oldLog.areaRadius : 0;
                const newAreaRadius = newLog.areaRadius ? newLog.areaRadius : 0;
                // Status
                const oldStatus = oldLog.isActive ? oldLog.isActive : 'false';
                const newStatus = newLog.isActive ? newLog.isActive : 'false';

                return oldDesc !== newDesc ||
                    oldAddress !== newAddress ||
                    oldLatitude !== newLatitude ||
                    oldLongitude !== newLongitude ||
                    oldAreaRadius !== newAreaRadius ||
                    oldStatus !== newStatus;
            });
            this.changeLogs = filteredLogs;
        },

        hasValueChanged(log, fieldName) {
            let oldLog = log.old ? log.old : {};
            let newLog = log.new ? log.new : {};

            let oldValue = oldLog[fieldName] ? oldLog[fieldName] : "-";
            let newValue = newLog[fieldName] ? newLog[fieldName] : "-";
            return oldValue !== newValue;
        },

        hasChangesInNestedFields(log, nestedfield, fieldName) {
            let oldLog = log.old ? log.old[nestedfield] : {};
            let newLog = log.new ? log.new[nestedfield] : {};

            let oldValue = oldLog[fieldName] ? oldLog[fieldName] : "-";
            let newValue = newLog[fieldName] ? newLog[fieldName] : "-";
            return oldValue !== newValue;
        },

        // UTILS
        getFormattedDateWithTime(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },
        getOldValue(log, fieldName) {
            let oldLog = log.old ? log.old : {};
            let value = oldLog[fieldName] ? oldLog[fieldName] : "-";
            return value;
        },
        getNewValue(log, fieldName) {
            let newLog = log.new ? log.new : {};
            let value = newLog[fieldName] ? newLog[fieldName] : "-";
            return value;
        },
    }
}
</script>

<style scoped>
.new-value {
    color: green;
}

.old-value {
    color: red;
}

.changed-section {
    margin-bottom: 10px;
}
</style>